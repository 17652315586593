import { graphql, PageProps } from "gatsby"
import Page from "@components/Account/Forgot/AccountForgot"

export type Props = PageProps<GatsbyTypes.PageAccountForgotQuery, GatsbyTypes.PageAccountForgotQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query PageAccountForgot {
    page: sanityPageAccountForgot {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
