import { memo } from "react"
import { Button, Stack, Text } from "@chakra-ui/react"
import { useCustomerRecover } from "@app/hooks/useCustomer"
import { useFormBuilder, FIELD_TYPES, ControlledInput, FunctionErrors } from "@app/hooks/useFormBuilder"

type AccountForgotFormProps = {
  buttonTitle?: string
}

const AccountForgotForm: React.FC<AccountForgotFormProps> = ({ buttonTitle }) => {
  const { handleChange, handleSubmit: handleCustomerRecover, data, loading, errors: functionErrors, success } = useCustomerRecover()

  const { fieldErrors, register, handleSubmit } = useFormBuilder({
    onSubmit: (data: any, event: any) => handleCustomerRecover(event),
  })

  return (
    <Stack as="form" onSubmit={handleSubmit} spacing={4}>
      <ControlledInput type={FIELD_TYPES.EMAIL} register={register} onChange={handleChange} fieldErrors={fieldErrors} data={data} />

      {buttonTitle && (
        <Button type="submit" isDisabled={loading} isLoading={loading}>
          {buttonTitle}
        </Button>
      )}

      <FunctionErrors errors={functionErrors} />
      {/* TODO: add to locales */}
      {success && <Text fontWeight={700}>Email Sent ✓</Text>}
    </Stack>
  )
}

const MemoAccountForgotForm = memo(AccountForgotForm)
export { MemoAccountForgotForm as AccountForgotForm }
